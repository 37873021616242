// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
//@import "variables";
@import "~aos/dist/aos.css"; // You can also use <link> for styles

// Bootstrap
@import "~bootstrap/scss/bootstrap";

.menu-lateral {
    background-image: url(/images/bg1.jpg);
    background-size: cover;
    background-position: 50% 80%;
    > h3 {
        text-shadow: 0px 4px 10px black;
    }
}

nav {
    z-index: 9999 !important;
}

.cursor-pointer {
    cursor: pointer;
}

// ocultar el botonGoTop
.hide-btn {
    z-index: 1000 !important;
    display: inline-block !important;
    pointer-events: none;
    transition: opacity 0.3s;
    opacity: 0;
}
// Mostrar el botonGoTop
.show-btn {
    pointer-events: all !important;
    opacity: 1 !important;
}
